.wavey {
  position: relative;
  margin-top: auto;
  overflow: hidden;

  @include desktop {
    z-index: 1;
  }

  .wavey-container {
    height: 80px;

    @include desktop {
      height: 200px;
    }
  }

  .wavey-container-right {
    background-color: $white-elementary;
  }

  .wavey-container-left {
    background-color: transparent;
  }

  .wavey-sizes {
    height: 101%;
    width: 100%;
  }

  .wavey-right {
    stroke: none;
    fill: $black;
  }

  .wavey-left {
    stroke: none;
    fill: $white-elementary;
  }
}

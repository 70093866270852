.footer {
  background-color: $black;
  color: $white-elementary;
  font-family: $roboto;

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  &__links-container {
    display: flex;
  }

  &__items-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 auto;

    @include desktop {
      flex-direction: row;
    }
  }

  &__links-items {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;

    @include desktop {
      margin: 0rem 3rem;
      cursor: pointer;
    }
  }

  &__links-items-services {
    margin: 1rem 0;
  }

  &__links-items h2 {
    font-size: 1.5rem;
    margin-bottom: 0.8rem;
    color: $green-medium;
  }

  &__links-items a {
    color: $white-elementary;
    font-size: 1.1rem;
    text-decoration: none;
  }

  &__icons-container {
    display: flex;

    @include desktop {
      flex-direction: row;
    }
  }

  &__icons-container {
    margin: 1rem 0.8rem;
    align-items: center;

    @include desktop {
      margin-bottom: 0.5rem ;
    }
  }

  &__icons-separation {
    margin: 10px;
  }

  &__website-right{
    align-content: center;
    display: flex;
    justify-content: center;
  }

  &__website-right small{
    font-size: 10px;
    margin-bottom: 16px;

    @include desktop {
      font-size: 16px;
    }
  }
}

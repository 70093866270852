.contact-us {
  align-items: center;
  background-color: $green-medium;
  display: flex;
  flex-direction: column;
  height: 30rem;
  justify-content: center;
  width: 100%;
  background-image: url('../../assets/contact/flower-down-mobile.svg'),
    url("../../assets/contact/flower-up-mobile.svg");
  background-repeat: no-repeat;
  background-position: 0% 100%, 100% 10%;

  @include desktop {
    background-image: none;
    background-image: url('../../assets/contact/flower-down-desktop.svg'),
    url("../../assets/contact/flower-up-desktop.svg");
    background-repeat: no-repeat;
    background-position: 0% 100%, 100% 10%;
    height: 40rem;
    width: 100%;
  }

  &__title {
    color: $green-darker;
    font-family: $roboto;
    font-size: 1.375rem;
    margin: 0;

    @include desktop {
      font-size: 3.75rem;
    }
  }

  &__contact {
    color: $white;
    font-family: $roboto;
    font-size: 2.5rem;
    margin: 1rem 0;

    @include desktop {
      font-size: 5rem;
    }
  }
}

.arrow-scroll {
  bottom: -35%;
  cursor: pointer;
  display: inline;
  position: relative;
  width: 2.8rem;

  @include desktop {
    bottom: -30%;
    width: 3.8rem;
  }
}

$cocogoose: 'cocogoose';
$roboto: 'roboto';

@font-face {
  font-family: 'cocogoose';
  src: local('cocogoose'),
  url('../../fonts/Cocogoose-Pro-trial.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'roboto';
  src: local('roboto'),
  url('../../fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
}

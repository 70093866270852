$black: #0c0c0c;
$gray-darker: #4a4a4a;
$gray: #c4c4c4;
$green-darker: #4a8616;
$green-lighter: #6ab429;
$green-medium: #6ab429;
$green-shadow: #b6ff7a;
$gree-button: #83e631;
$principal-background: #e5e5e5;
$white-elementary: #f8f8f8;
$white: #ffffff;
$overlay: #353333b3;
$icon-color-dekstop: #6AB429;
$icon-color-mobile: #E5E5E5;

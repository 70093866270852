.container-information {
  background-color: $white;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 64px);
  justify-content: flex-start;
  overflow-x: hidden;
  position: relative;
  text-align: center;

  @include desktop {
    overflow-x: initial;
  }

  &__girl {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    width: 500px;

    @include desktop {
      bottom: 10px !important;
      left: initial;
      right: 0px !important;
      width: initial;
    }
  }

  &__guy {
    display: none;

    @include desktop {
      bottom: 0;
      display: block;
      left: -30px;
      position: absolute;
    }
  }

  &__left-top-flower {
    display: none;

    @include desktop {
      bottom: 610px !important;
      display: block;
      overflow: hidden;
      position: absolute;
      width: 90px;
    }
  }

  &__left-down-flower {
    bottom: 450px !important;
    overflow: hidden;
    position: absolute;
    width: 70px;

    @include desktop {
      bottom: 460px !important;
      overflow: hidden;
      position: absolute;
      width: 75px;
    }
  }

  &__right-top-flower {
    display: none;

    @include desktop {
      bottom: 610px !important;
      display: block;
      overflow: hidden;
      position: absolute;
      right: 0px !important;
      width: 70px;
    }
  }

  &__right-down-flower {
    bottom: 490px !important;
    overflow: hidden;
    position: absolute;
    right: 0px !important;
    width: 54px;

    @include desktop {
      bottom: 460px !important;
      overflow: hidden;
      position: absolute;
      right: 0px !important;
      width: 60px;
    }
  }

  &__title {
    color: $black;
    font-family: $cocogoose;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 5%;
    margin: 5rem 1rem 0 1rem;
    z-index: 100;

    @include desktop {
      font-size: 3.75rem;
      line-height: 70px;
      margin: 0 auto;
      margin-top: 8rem;
      width: 50rem;
    }
  }

  &__subtitle {
    color: $gray-darker;
    font-family: $roboto;
    font-size: 0.8rem;
    font-weight: 400;
    margin: 1rem 1rem 3rem;

    @include desktop {
      font-size: 1.1rem;
    }
  }

  &__button {
    background-color: $gree-button;
    border-radius: 3.75rem;
    border: none;
    box-shadow: 0 0.5rem 2rem $green-shadow;
    color: $white;
    font-family: $roboto;
    font-size: 1rem;
    font-weight: 600;
    margin: 0 auto;
    max-width: 8rem;
    padding: 0.7rem 2.75rem;
    text-decoration: none;
    transition: background-color 0.1s ease-in;
    text-transform: uppercase;
  }

  &__button:hover {
    background-color: $green-lighter;
  }

  &_info {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.header {
  background-color: $white;
  width: 100%;
  z-index: 100;

  .header-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 4rem;
    justify-content: space-between;
    margin: 0 1rem;

    @include desktop {
      height: 5rem;
      margin: 0 5rem;
    }
  }

  .logos-container {
    &__desktop {
      display: none;

      @include desktop {
        display: block;
      }
    }

    &__mobile {
      width: 2.5rem;

      @include desktop {
        display: none;
      }
    }
  }

  .menu-container {
    @include desktop {
      display: flex;
      flex-direction: row;
    }

    &__hamburguer-icon {
      width: 2rem;
      cursor: pointer;

      @include desktop {
        display: none;
      }
    }

    &__link {
      display: none;
      cursor: pointer;

      @include desktop {
        color: $gray-darker;
        display: block;
        font-family: $roboto;
        font-style: normal;
        margin: 0 1rem;
        text-decoration: none;
      }
    }
  }
}
